<template>
  <router-view/>
</template>
<script>
import './assets/scss/style.scss'
export default {
  name: 'App',
  components: {
  }
}
</script>
<style lang="scss">
</style>
